import React, { useState, useEffect, useRef } from "react";
import  userLogo  from '../images/User.png'
import './participantWrapper.css';

const LocalParticipant = ({ time,participant,vidMic }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  

  const videoRef = useRef();
  const audioRef = useRef();
  
  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

 const Participant = () =>{
  setVideoTracks(trackpubsToTracks(participant.videoTracks));
  setAudioTracks(trackpubsToTracks(participant.audioTracks));

  const trackSubscribed = (track) => {
    if (track.kind === "video") {
      setVideoTracks((videoTracks) => [...videoTracks, track]);
    } else if (track.kind === "audio") {
      setAudioTracks((audioTracks) => [...audioTracks, track]);
    }
  };

  const trackUnsubscribed = (track) => {
    if (track.kind === "video") {
      setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
    } else if (track.kind === "audio") {
      setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
    }
  };
  participant.on("trackSubscribed", trackSubscribed);
  participant.on("trackUnsubscribed", trackUnsubscribed);

  return () => {
    setVideoTracks([]);
    setAudioTracks([]);
    participant.removeAllListeners();
  };
 }
  useEffect(() => {
    Participant();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);
  
  return (
    <div className='localParticipant'>
      { vidMic.video && <video ref={videoRef} autoPlay={true} local={true} /> }
      <audio ref={audioRef} autoPlay={true} muted={false} />
      { !vidMic.video && (
        <div className='user_info'>
         <div data-set={vidMic.microphone}>
              <div data-set={vidMic.microphone}>
                <img src={userLogo} alt='logo' />
              </div>
         </div>         
            <h3>{participant.identity}</h3>
            <h3>{time.hour+':'+time.min+':'+time.sec}</h3>
        </div>
      )}
    </div>
  )
}

export default LocalParticipant;