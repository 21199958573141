import React from "react";
import "./popup.css";

const Popup = ({ onConfirm, onCancel }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <p className="message_title">
          Attention, this meeting is about to end in the next few minutes.
        </p>
        <p className="message_desc">
          If you need more time, you can extend the meeting for an additional{" "}
          <span className="message_time">5 </span>
          minutes.
        </p>
        <div className="buttons_wrapper">
          <button onClick={onCancel} className="buttons cancel">
            Cancel
          </button>
          <button onClick={onConfirm} className="buttons">
            Extend
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
