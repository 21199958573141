import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import VideoChat from "./components/VideoChat";

const App = () => {
  return (
    <div className="app">
      <main>
        <Router>
          <Routes>
            <Route path="/" element={<VideoChat />} />
          </Routes>
        </Router>
      </main>
    </div>
  );
};

export default App;
