import React, { useEffect, useRef } from "react";
import "./lobbyWrapper.css";

const Lobby = ({ vidMic, join, getVideoCallData }) => {
  const videoRef = useRef();
  useEffect(() => {
    if (vidMic.video) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((mediaStream) => {
          if (videoRef?.current?.srcObject)
            videoRef.current.srcObject = mediaStream;
        });
    } else if (!vidMic.video && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream?.getTracks();
      tracks?.forEach(function (track) {
        track.stop();
      });
    }
  }, [vidMic.video, videoRef]);

  useEffect(() => {
    if (join) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach(function (track) {
        track.stop();
      });
      getVideoCallData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [join]);
  return (
    <div className="videoconference_lobby">
      <div className="lobby_join_content">
        <video ref={videoRef} autoPlay={true} local={true} />
      </div>
    </div>
  );
};

export default Lobby;
