import React, { useState, useEffect } from "react";
import LocalParticipant from "../Participant/LocalParticipant";
import RemoteParticipants from "../Participant/RemoteParticipants";
import "./roomWrapper.css";

const Room = ({ room, openTwilioVideo, vidMic }) => {
  const [participants, setParticipants] = useState([]);
  const [time, setTime] = useState({ hour: "00", min: "00", sec: "00" });

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.participants.forEach(participantConnected);
    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
    };
  }, [room]);

  useEffect(() => {
    if (!vidMic.video) {
      room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.disable();
        publication.track.stop();
      });
    } else if (vidMic.video && !vidMic.isVideoFirst) {
      room.localParticipant.tracks.forEach((trackPub) => {
        trackPub.track.stop();
      });
      room.disconnect();
      openTwilioVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vidMic.video]);

  useEffect(() => {
    if (!vidMic.microphone) {
      room.localParticipant.audioTracks.forEach((publication) =>
        publication.track.disable()
      );
    } else if (vidMic.microphone) {
      room.localParticipant.audioTracks.forEach((publication) =>
        publication.track.enable()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vidMic.microphone]);

  const myTimer = () => {
    let minut = time.min * 1;
    let hour = time.hour * 1;
    if (minut === 60 && time.sec === "01") {
      hour += 1;
      if (hour < 10) hour = "0" + hour;
      time.hour = hour;
      time.min = "00";
      minut = 0;
    }
    if (time.sec === 60) {
      minut += 1;
      if (minut < 10) minut = "0" + minut;
      time.min = minut;
      time.sec = 0;
    }
    let sec = time.sec * 1 + 1;

    if (sec < 10) sec = "0" + sec;
    time.sec = sec;
    setTime({ ...time });
  };

  useEffect(() => {
    setInterval(myTimer, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="room">
      <div className={participants?.length && "remote_participants"}>
        {participants.map((participant) => {
          const isConected =
            Array.from(participant?.videoTracks?.values())[0]?.track || false;
          const videoIsEnabled = Array.from(
            participant?.videoTracks?.values()
          )[0]?.track?.isEnabled;
          const audioIsEnabled = Array.from(
            participant?.audioTracks?.values()
          )[0]?.track?.isEnabled;
          return (
            <>
              {isConected && (
                <RemoteParticipants
                  key={participant.sid}
                  participant={participant}
                  videoIsEnabled={videoIsEnabled}
                  audioIsEnabled={audioIsEnabled}
                />
              )}
            </>
          );
        })}
      </div>
      <div
        className={
          !participants?.length
            ? "local_participant_largeSize"
            : "local_participant_smallSize"
        }
      >
        {room && (
          <LocalParticipant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            participants={participants?.length || false}
            vidMic={vidMic}
            time={time}
          />
        )}
      </div>
    </div>
  );
};

export default Room;
