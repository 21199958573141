import React from "react";
import "./headerWrapper.css";
import logo from "../images/LawVo.svg";

const Header = ({ userData }) => {
  return (
    <div>
      <div className="video_conference_header">
        <img alt="LawVO" src={logo} />
        <div className="video_conference_header_identity">
          <div>{userData?.username || ""}</div>
          <img alt="logo" src={userData?.photo} />
        </div>
      </div>
    </div>
  );
};

export default Header;
