import React, { useState, useEffect } from "react";
import { ReactComponent as Call } from "../images/Call.svg";
import { ReactComponent as Video } from "../images/Video.svg";
import { ReactComponent as Microphone } from "../images/Microphone.svg";
import "./contolCallWrapper.css";
import Popup from "../popup/Popup";

const ControlCall = ({
  handleLogout,
  vidMic,
  setVidMic,
  setJoin,
  room,
  videoCallData,
  userData,
}) => {
  const [timeLeft, setTimeLeft] = useState();
  const [confirmationShown, setConfirmationShown] = useState(false);
  const [warning, setWarning] = useState(false);
  const [isExtend, setIsExtend] = useState(false);

  const isLawyer = videoCallData?.userId !== userData?.userId;

  useEffect(() => {
    setTimeLeft(videoCallData?.duration * 60);
  }, [videoCallData?.duration]);

  useEffect(() => {
    if (timeLeft <= 0) {
      handleLogout();
      return;
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  useEffect(() => {
    if (timeLeft === 60 && !confirmationShown) {
      setWarning(true);
    }
  }, [timeLeft, confirmationShown]);

  useEffect(() => {
    if (isExtend) {
      setTimeLeft(timeLeft + 300);
      setTimeout(() => setConfirmationShown(true), 60000);
      setIsExtend(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warning]);

  const changeStyle = (e) => {
    if (e === "video")
      return setVidMic({
        ...vidMic,
        [e]: vidMic[e] === true ? false : true,
        isVideoFirst: false,
      });
    setVidMic({ ...vidMic, [e]: vidMic[e] === true ? false : true });
  };

  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = timeLeft % 60;

  return (
    <div>
      {!room ? (
        <div className="ControlCall">
          <div
            className="ControlCall_microphone border"
            data-set={vidMic.microphone}
            onClick={() => changeStyle("microphone")}
          >
            {!vidMic.microphone && <hr />}
            <Microphone />
          </div>
          <div
            className="ControlCall_video border"
            data-set={vidMic.video}
            onClick={() => changeStyle("video")}
          >
            {!vidMic.video && <hr />}
            <Video />
          </div>
          <div
            className="ControlCall_join"
            onClick={() => {
              setJoin(true);
            }}
          >
            Join
          </div>
        </div>
      ) : (
        <div className="ControlCall">
          <div
            className="ControlCall_microphone border"
            data-set={vidMic.microphone}
            onClick={() => changeStyle("microphone")}
          >
            {!vidMic.microphone && <hr />}
            <Microphone />
          </div>
          <div
            className="ControlCall_video border"
            data-set={vidMic.video}
            onClick={() => changeStyle("video")}
          >
            {!vidMic.video && <hr />}
            <Video />
          </div>
          <div className="ControlCall_endCall" onClick={handleLogout}>
            <Call />
          </div>
          <div className="timeWrapper">
            {warning && isLawyer && (
              <Popup
                onConfirm={() => {
                  setIsExtend(true);
                  setWarning(false);
                }}
                onCancel={() => {
                  setIsExtend(false);
                  setWarning(false);
                }}
              />
            )}
            <div style={{ color: timeLeft <= 60 ? "red" : "white" }}>
              <span>{hours < 10 ? `0${hours}` : hours}</span>:
              <span>{minutes < 10 ? `0${minutes}` : minutes}</span>:
              <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ControlCall;
//   /// box-shadow: 1px 0px 14px rgb(255 255 255 / 25%);
