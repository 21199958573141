import React, { useState, useEffect, useRef } from "react";
import  userLogo  from '../images/User.png'
import './participantWrapper.css';
import { ReactComponent as Microphone } from '../images/Microphone.svg';

const RemoteParticipantS = ({ participant,videoIsEnabled,audioIsEnabled}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);

  const videoRef = useRef();
  const audioRef = useRef();
  
  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      console.log('trackSubscribed',track)
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      console.log('trackUnsubscribed',track)
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };
    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);
  
  return (
    <div className='localParticipant'>
      {
        videoIsEnabled ?
           <video ref={videoRef} autoPlay={true} local={true} />
        :
        <div className='user_info'>
         <div data-set={audioIsEnabled}>
              <div data-set={audioIsEnabled}>
                <img src={userLogo} alt='logo' />
              </div>
         </div>
         <nav className='remote_user_info_microphone_name'>
              <div className='remote_user_info_microphone_atr'>
                  {!audioIsEnabled && <hr/> }            
                  <Microphone />
              </div>     
              <h3>{participant.identity}</h3>
         </nav>
        </div>
      }
      
      <audio ref={audioRef} autoPlay={true} muted={false} />
      
    </div>
  )
}

export default RemoteParticipantS;