import React, { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DeviceUUID } from "device-uuid";
import CryptoJS from "crypto-js";
import Video from "twilio-video";
import Lobby from "./Lobby/Lobby";
import Room from "./Room/room";
import ControlCall from "./ControlCall/controlCall";
import "./videoChatWrapper.css";
import Headers from "./Header/Header.js";

const useQuery = () => new URLSearchParams(useLocation().search);
const VideoChat = () => {
  const query = useQuery();
  const [hasPermission, setHasPermission] = useState(false);
  const [permissionMessage, setPermissionMessage] = useState("");
  const [username, setUsername] = useState("");
  const [roomName, setRoomName] = useState("");
  const [room, setRoom] = useState(null);
  const [connecting, setConnecting] = useState(false);
  const [join, setJoin] = useState(false);
  const [vidMic, setVidMic] = useState({
    video: true,
    microphone: true,
    isVideoFirst: true,
  });
  const [videoCallData, setVideoCallData] = useState(null);
  const [userData, setUserData] = useState(null);

  const connect = async () => {
    setConnecting(true);
    const url =
      process.env.NODE_ENV === "production"
        ? window.location.origin
        : "http://localhost";

    const res = await fetch(
      `${url}:${process.env.REACT_APP_PORT || 8081}/video/token`,
      {
        method: "POST",
        body: JSON.stringify({
          identity: username,
          room: roomName,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    Video.connect(data.token, {
      name: roomName,
    })
      .then((room) => {
        !vidMic.microphone
          ? room.localParticipant.audioTracks.forEach((publication) =>
              publication.track.disable()
            )
          : room.localParticipant.audioTracks.forEach((publication) =>
              publication.track.enable()
            );
        setConnecting(false);
        setRoom(room);
      })
      .catch((err) => {
        console.error(err);
        setConnecting(false);
      });
  };

  const handleLogout = useCallback(() => {
    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
        window.close();
      }
      return null;
    });
  }, []);

  const getVideoCallData = async () => {
    let code;
    let token;
    let username;
    const hashedParam = query.get("id");
    const uuid = new DeviceUUID().get();
    const decData = CryptoJS.enc.Base64.parse(hashedParam).toString(
      CryptoJS.enc.Utf8
    );
    const bytes = CryptoJS.AES.decrypt(decData, uuid.substring(1, 10));
    try {
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if (decryptedData !== null) {
        setUserData(decryptedData);
        code = decryptedData?.code;
        token = decryptedData?.token;
        username = decryptedData?.username;
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
      console.log(e);
    }
    const videoCallData = await fetch(
      `${
        process.env.REACT_APP_API_URL || "https://stagingapi.lawvo.com"
      }/video-calls?code=${code}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((res) => {
        setHasPermission(res.status === 200);
        setPermissionMessage("Permission to this video call is denied");
        return res.json();
      })
      .catch(() => {
        setHasPermission(false);
        setPermissionMessage("Permission to this video call is denied");
      });
    if (videoCallData?.data) {
      setVideoCallData(videoCallData?.data[0]);
      const start = new Date(videoCallData?.data[0]?.startDate);
      // for testing put current dateTime +- 15min  ex. "2023-05-05 16:15"
      // const start = new Date("2023-05-26 12:04"); // for testing put current dateTime +- 15min  ex. "2023-05-05 16:15"
      const now = new Date();
      const diff = (now - start) / (1000 * 60);
      if (diff >= -15 && diff <= 15) {
        setUsername(username);
        setRoomName(code);
      } else {
        setPermissionMessage(
          "Oops! It looks like you've connected to the wrong time slot. Please check your scheduled time and try again. Thank you for your cooperation."
        );
        setHasPermission(false);
      }
    } else {
      setHasPermission(false);
      setPermissionMessage(
        "Oops! It looks like you've connected to the wrong time slot. Please check your scheduled time and try again. Thank you for your cooperation."
      );
    }
  };
  useEffect(() => {
    getVideoCallData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (username && roomName) connect();
    // eslint-disable-next-line
  }, [username, roomName]);

  useEffect(() => {
    if (room) {
      const tidyUp = (event) => {
        if (event.persisted) {
          return;
        }
        if (room) {
          handleLogout();
        }
      };
      window.addEventListener("pagehide", tidyUp);
      window.addEventListener("beforeunload", tidyUp);
      return () => {
        window.removeEventListener("pagehide", tidyUp);
        window.removeEventListener("beforeunload", tidyUp);
      };
    }
  }, [room, handleLogout]);

  return (
    <>
      {!hasPermission && (
        <div className="permissionDeniedContainer">
          <div className="lobby_join_content" style={{ color: "white" }}>
            {permissionMessage || "Permission is denied"}
          </div>
        </div>
      )}
      {hasPermission && (
        <div className="content">
          <Headers userData={userData} />
          {room ? (
            <Room
              roomName={roomName}
              room={room}
              setRoom={setRoom}
              openTwilioVideo={connect}
              vidMic={vidMic}
            />
          ) : (
            <Lobby
              vidMic={vidMic}
              connecting={connecting}
              join={join}
              getVideoCallData={getVideoCallData}
            />
          )}
          <ControlCall
            handleLogout={handleLogout}
            vidMic={vidMic}
            setVidMic={setVidMic}
            setJoin={setJoin}
            room={room}
            videoCallData={videoCallData}
            userData={userData}
          />
        </div>
      )}
    </>
  );
};

export default VideoChat;
